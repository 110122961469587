import React from "react";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import RF from "../images/rainforest-alliance.png";
/* import Lidl from "../images/lidl.png"; */
import FT from "../images/fairtrade.png";
import SFE from "../images/SFE.png";
/* import Euro from "../images/euro.png";
import Coop from "../images/Coop.svg.png"; */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alertas: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundBlendMode: "soft-light",
    backgroundSize: "104px",
  },
}));

export function Alertas(props) {
  const classes = useStyles();
  //console.log(props.agroData.ingredientes[0]);
  function tipoAlerta(tipo) {
    switch (tipo) {
      case "Si":
      case "Permitido":
        return "success";
      case "Restringido":
        return "warning";
      case "No":
        return "error";
      default:
        return "error";
    }
  }

  if (props.agroData) {
    /*  console.log(props.agroData.ingredientes[0].sfe == "Si"); */
    return (
      <>
        <Alert severity="info" variant="outlined">
          <AlertTitle>Recomendaciones</AlertTitle>
          {props.agroData.recomendaciones}
          <br />
          Dosis: {props.agroData.dosis}
        </Alert>
        <Alert
          severity={tipoAlerta(
            props.agroData.ingredientes[0].sfePina
              ? props.agroData.ingredientes[0].sfe == "Si"
                ? "Si"
                : "Restringido"
              : ""
          )}
          variant="filled"
          className={classes.alertas}
          style={{
            backgroundImage: `url(${SFE})`,
          }}
        >
          <AlertTitle>Servicio Fitosanitario del Estado</AlertTitle>
          {props.agroData.ingredientes[0].sfe}. (Registrado Piña:
          {props.agroData.ingredientes[0].sfePina ? " Si" : " No"})
        </Alert>

        {props.agroData.ingredientes[0].rainforest && (
          <>
            <Alert
              severity={tipoAlerta(props.agroData.ingredientes[0].rainforest)}
              variant="filled"
              className={classes.alertas}
              style={{
                backgroundImage: `url(${RF})`,
              }}
            >
              <AlertTitle>Rainforest Alliance</AlertTitle>
              {props.agroData.ingredientes[0].rainforest}
            </Alert>
          </>
        )}
        {props.agroData.ingredientes[0].fairTrade && (
          <>
            <Alert
              severity={tipoAlerta(props.agroData.ingredientes[0].fairTrade)}
              variant="filled"
              className={classes.alertas}
              style={{
                backgroundImage: `url(${FT})`,
              }}
            >
              <AlertTitle>Fairtrade</AlertTitle>
              {props.agroData.ingredientes[0].fairTrade}
            </Alert>
          </>
        )}
        {props.agroData.ingredientes[0].supermecadosLidl && (
          <>
            <Alert
              severity={tipoAlerta(
                props.agroData.ingredientes[0].supermecadosLidl
              )}
              variant="filled"
              className={classes.alertas}
              /*               style={{
                backgroundImage: `url(${Lidl})`,
              }} */
            >
              <AlertTitle>Supermecados L</AlertTitle>
              {props.agroData.ingredientes[0].supermecadosLidl}
            </Alert>
          </>
        )}
        {props.agroData.ingredientes[0].supermercadosEuro && (
          <>
            <Alert
              severity={tipoAlerta(
                props.agroData.ingredientes[0].supermercadosEuro
              )}
              variant="filled"
              className={classes.alertas}
              /*               style={{
                backgroundImage: `url(${Euro})`,
              }} */
            >
              <AlertTitle>Supermercado E</AlertTitle>
              {props.agroData.ingredientes[0].supermercadosEuro}
            </Alert>
          </>
        )}
        {props.agroData.ingredientes[0].supermercadosCOOP && (
          <>
            <Alert
              severity={tipoAlerta(
                props.agroData.ingredientes[0].supermercadosCOOP
              )}
              variant="filled"
              className={classes.alertas}
              /*               style={{
                backgroundImage: `url(${Coop})`,
              }} */
            >
              <AlertTitle>Supermercados C</AlertTitle>
              {props.agroData.ingredientes[0].supermercadosCOOP}
            </Alert>
          </>
        )}
      </>
    );
  } else {
    return null;
  }
}
