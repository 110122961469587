import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BugReportOutlinedIcon from "@material-ui/icons/BugReportOutlined";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: "50px",
    height: "50px",
  },

  MuiListItemAvatar: {
    minWidth: "65px",
  },
}));

export default function Plagas(props) {
  //console.log(props);
  const classes = useStyles();
  const plagas = props.plagasData.map((e) => (
    <List className={classes.root} key={e.id}>
      <ListItem
        button
        component={RouterLink}
        to={{ pathname: `/plaga/${e.id}` }}
      >
        <ListItemAvatar className={classes.MuiListItemAvatar}>
          <Avatar className={classes.avatar}>
            {(e.foto && e.foto.formats && e.foto.formats.thumbnail && (
              <img
                src={
                  "https://admin.frutaapp.com" + e.foto.formats.thumbnail.url
                }
                width="80px"
              />
            )) || <BugReportOutlinedIcon fontSize="large" />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={e.nombre} secondary={e.tipo} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  ));

  return <>{plagas}</>;
}
