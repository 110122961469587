import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  input: {
    backgroundColor: "white",
  },
});

export default function Search(agroData) {
  // const { Storage } = Plugins;
  const [data, setData] = useState([]);
  useEffect(async () => {
    //await agroinsumosData();

    //const result = await Storage.get({ key: "agroinsumos" });

    //setData(JSON.parse(result.value));
    setData(agroData.agroData.agroData);
  }, [agroData]);
  //console.log(data);
  const history = useHistory();
  const classes = useStyles();

  const options = data.map((option) => {
    const trimmedNombre = option.nombre.trim();
    const firstLetter = trimmedNombre[0].toUpperCase();

    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      onChange={(event, value) => {
        if (value) {
          if ("foto" in value) {
            history.push({ pathname: `/plaga/${value.id}` });
          } else {
            if ("ingredientes" in value) {
              history.push({ pathname: `/agroinsumo/${value.id}` });
            } else {
              if ("dosificacion" in value) {
                history.push({ pathname: `/bioinsumo/${value.id}` });
              }
            }
          }
        }
      }}
      blurOnSelect="touch"
      clearOnBlur={true}
      className={classes.root}
      id="grouped"
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.nombre}
      renderInput={(params, option) => (
        <TextField
          {...params}
          label="Haga su búsqueda aquí"
          variant="filled"
          className={classes.input}
        />
      )}
    />
  );
}
