import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Search from "./search";
import { Plugins } from "@capacitor/core";
const { SplashScreen } = Plugins;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FECC5D",
    padding: "10px",
  },
}));

export default function PineAppBar(agroData) {
  const classes = useStyles();
  /*   console.log("agroData.agroData");
  console.log(agroData.agroData); */

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {agroData.agroData.length > 2 && SplashScreen.hide() && (
          <Search agroData={agroData}></Search>
        )}
      </AppBar>
    </div>
  );
}
