import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink, useParams } from "react-router-dom";
import Plagas from "./plagas";
import { Plugins } from "@capacitor/core";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { bioinsumosData } from "../data/bioinsumosData";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

const { Storage } = Plugins;
const useStyles = makeStyles((theme) => ({
  alertas: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    paddingBottom: "30px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  notadosis: { fontSize: 13 },
  avatar: { width: theme.spacing(3), height: theme.spacing(3) },
}));
function GetData(bioData) {
  let { id } = useParams();

  let dataaa = bioData.find((item) => item.id == id);

  return dataaa;
}

export default function Bioinsumo(props) {
  const [bioData, setbioData] = useState([]);

  useEffect(async () => {
    /* await bioinsumosData(); */

    const result = await Storage.get({ key: "bioinsumos" });
    setbioData(JSON.parse(result.value));
  }, []);
  const classes = useStyles();

  var agroData = GetData(bioData);
  if (agroData) {
    //console.log(agroData);
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography
            variant="h4"
            component="h2"
            children={agroData.nombre}
          ></Typography>
          <AvatarGroup>
            <Avatar
              className={classes.avatar}
              src={
                agroData &&
                "https://admin.frutaapp.com" +
                  agroData.cultivos[0].icono.formats.thumbnail.url
              }
            ></Avatar>

            <Avatar
              className={classes.avatar}
              src={
                agroData &&
                "https://admin.frutaapp.com" + agroData.paises[0].bandera.url
              }
            ></Avatar>
          </AvatarGroup>
          <Typography
            className={classes.pos}
            color="textSecondary"
            children={agroData.preventivoCurativo}
          />

          <div className={classes.alertas}>
            {agroData.dosificacion && (
              <Alert severity="info" variant="outlined">
                <AlertTitle>Dosificación</AlertTitle>
                {agroData.dosificacion}
                <br />
                <div className={classes.notadosis}>
                  * Las dosis de los bioinsumos quedan sujetas a la
                  recomendación de un profesional (Ing. Agrónomo)
                </div>
              </Alert>
            )}
            {agroData.recomendaciones && (
              <Alert severity="info" variant="outlined">
                <AlertTitle>Recomendaciones</AlertTitle>
                {agroData.recomendaciones}
              </Alert>
            )}
          </div>
          {agroData.plagases && agroData.plagases[0] && (
            <div>
              <Typography variant="h4" component="h2">
                Plagas
              </Typography>
              <Plagas plagasData={agroData.plagases} />
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button component={RouterLink} to="/bioinsumos" size="large">
            Volver
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return null;
  }
}
